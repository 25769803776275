import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { HiMenu } from "react-icons/hi";
import applicationLogo from "../../src/images/Logo/logo.png"; // Assuming your logo path is correct
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { logout } from "../services/apiServices";
import { useDispatch } from "react-redux";

function AdminIndex() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [greeting, setGreeting] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Good Morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  }, []);

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  const signOut = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    await dispatch(logout({ refreshToken: refreshToken }));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row min-h-screen bg-[#f9fafb]">
        {/* Desktop Sidebar */}
        <div className="w-[25%]">
          <div className="h-[calc(100vh-100px)] fixed m-5 py-2 lg:px-7 flex-col gap-20 rounded-2xl bg-[#f2f3f5] hidden md:flex">
            <img src={applicationLogo} alt="logo" className="w-16" />
            <div className="flex flex-col gap-2 text-[#8e96a7]">
              <Link
                to="/index"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Statistics</div>
              </Link>
              <Link
                to="/index/CompanyIntroductionVideo"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/CompanyIntroductionVideo"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Company Introduction Video</div>
              </Link>
              <Link
                to="/index/projectHistory"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/projectHistory"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Project History</div>
              </Link>
              <Link
                to="/index/clientReview"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/clientReview" ? "font-bold" : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Client Review</div>
              </Link>
              <Link
                to="/index/clientConsultations"
                className={`flex gap-2 items-center ${
                  location.pathname === "/index/clientConsultations"
                    ? "font-bold"
                    : ""
                }`}
              >
                <FaArrowRight className="inline" />
                <div>Client Consultations</div>
              </Link>
            </div>
            <div
              className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white font-extrabold shadow-lg hover:shadow-xl hover:from-purple-600 hover:to-indigo-600 transform transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
              onClick={signOut}
            >
              Sign Out
            </div>
          </div>
        </div>

        {/* Mobile Drawer */}
        <div className="fixed  shadow-sm w-full z-50">
          <div className="md:hidden flex flex-col gap-20 p-4 bg-white h-10 z-50">
            <div className="flex flex-row justify-between gap-2 items-center">
              <div className="flex items-center gap-2">
                <button onClick={toggleDrawer}>
                  <HiMenu />
                </button>
                <img src={applicationLogo} alt="logo" className="w-4 h-4" />
              </div>
            </div>
            <Drawer
              open={isOpen}
              onClose={toggleDrawer}
              size={200}
              direction="left"
              className="flex flex-col gap-20 py-3 px-3"
              style={{
                height: "calc(100vh - 100px)",
              }}
            >
              <div className="flex flex-col gap-2 text-[#8e96a7]">
                <div className="flex justify-between items-center">
                  <img src={applicationLogo} alt="logo" className="w-10 h-10" />
                  <div className="p-4">
                    <ImCross onClick={toggleDrawer} className="text-xl" />
                  </div>
                </div>
                <Link
                  to="/index"
                  className={`flex gap-2 items-center ${
                    location.pathname === "/index" ? "font-bold" : ""
                  }`}
                >
                  <FaArrowRight className="inline" />
                  <div>Statistics</div>
                </Link>
                <Link
                  to="/index/CompanyIntroductionVideo"
                  className={`flex gap-2 items-center ${
                    location.pathname === "/index/CompanyIntroductionVideo"
                      ? "font-bold"
                      : ""
                  }`}
                >
                  <FaArrowRight className="inline" />
                  <div>Company Introduction Video</div>
                </Link>
                <Link
                  to="/index/projectHistory"
                  className={`flex gap-2 items-center ${
                    location.pathname === "/index/projectHistory"
                      ? "font-bold"
                      : ""
                  }`}
                >
                  <FaArrowRight className="inline" />
                  <div>Project History</div>
                </Link>
                <Link
                  to="/index/clientReview"
                  className={`flex gap-2 items-center ${
                    location.pathname === "/index/clientReview"
                      ? "font-bold"
                      : ""
                  }`}
                >
                  <FaArrowRight className="inline" />
                  <div>Client Review</div>
                </Link>
                <Link
                  to="/index/clientConsultations"
                  className={`flex gap-2 items-center ${
                    location.pathname === "/index/clientConsultations"
                      ? "font-bold"
                      : ""
                  }`}
                >
                  <FaArrowRight className="inline" />
                  <div>Client Consultations</div>
                </Link>
              </div>
              <div
                className="px-6 py-3 bg-gradient-to-r from-purple-500 to-indigo-500 rounded-full text-white font-extrabold shadow-lg hover:shadow-xl hover:from-purple-600 hover:to-indigo-600 transform transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
                onClick={signOut}
              >
                Sign Out
              </div>
            </Drawer>
          </div>
        </div>

        {/* Main Content Area */}
        <div className="flex flex-col w-full md:w-[70%] mt-12">
          <div className="p-4 flex justify-between items-center">
            <div>
              <h1 className="text-xl font-semibold">Hello, Admin</h1>
              <p className="md:ml-2 text-slate-500">{greeting}</p>
            </div>
          </div>
          <div className="p-4">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminIndex;
