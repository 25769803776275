import { combineReducers } from "redux";
import authReducer_admin from "./admin/authReducer_admin";
// import other reducers here if any

const appReducer = combineReducers({
  authReducer_admin: authReducer_admin,
  // add other reducers here
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
