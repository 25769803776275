import React, { useState, useEffect } from "react";
import CommonButton from "../components/Buttons/CommonButton";
import FileDropper from "../components/fileDropper/FileDroper";
import { toast } from "react-toastify";
import {
  getIntroductionVideo,
  introductionVideo,
} from "../services/apiServices"; // Import the introductionVideo API action
import { useDispatch } from "react-redux";

const CompanyIntroductionVideo = () => {
  const [videoFile, setVideoFile] = useState(null); // To store the uploaded video file
  const [existingVideo, setExistingVideo] = useState(null); // To store the existing video URL
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Fetch the introduction video on component mount
  useEffect(() => {
    const fetchVideo = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getIntroductionVideo());
        if (response && response.data && response.data.introductionVideo) {
          setExistingVideo(response.data.introductionVideo);
        }
      } catch (error) {
        toast.error("Failed to fetch the introduction video.");
      } finally {
        setLoading(false);
      }
    };

    fetchVideo();
  }, [dispatch]);

  // Handle file drop from the FileDropper component
  const handleFileDrop = (uploadedFile) => {
    console.log("uploadedFile", uploadedFile);
    setVideoFile(uploadedFile);
    toast.success("Video file uploaded successfully!");
  };

  const handleSave = async () => {
    setLoading(true);

    try {
      // Call the introductionVideo API to upload the video
      const response = await dispatch(
        introductionVideo({
          introductionVideo: videoFile ? videoFile : null,
        })
      );
      if (response.status === "success") {
        toast.success("Video saved successfully!");
        setExistingVideo(response.data.introductionVideo); // Update the existing video
        setVideoFile(null); // Clear the uploaded video
      } else {
        toast.error("Failed to save the video.");
      }
    } catch (error) {
      toast.error(`Failed to save video: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  function isValidUrl(string) {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Upload Introduction Video</h2>

      {/* Show the existing video if it exists */}
      {existingVideo && !videoFile && (
        <div className="mt-4">
          <h4 className="font-semibold">Existing Introduction Video:</h4>
          <video
            src={existingVideo}
            controls
            className="w-full max-w-md mt-2 rounded-md"
          ></video>
        </div>
      )}

      {/* File Dropper Component */}
      <FileDropper
        title="Drag and drop video here"
        instructions="Upload an introduction video (MP4 format)"
        onFileDrop={(fileURL) => handleFileDrop(fileURL)}
        fileTypes={["mp4", "mov"]}
        maxFiles={1}
        type="video"
        defaultFile={existingVideo}
      />

      {/* Show the uploaded video preview */}
      {isValidUrl(videoFile) && (
        <div className="mt-4">
          <h4 className="font-semibold">Uploaded Video:</h4>
          <video
            src={videoFile} // Display only if it's a valid URL
            controls
            className="w-full max-w-md mt-2 rounded-md"
          ></video>
        </div>
      )}

      {/* Save Button */}
      <div className="mt-6">
        <CommonButton
          label={loading ? "Saving..." : "Save Video"}
          onClick={handleSave}
          disabled={loading}
          className="bg-purpleButton text-white px-4 py-2 rounded-md hover:bg-purple-700"
        />
      </div>
    </div>
  );
};

export default CompanyIntroductionVideo;
