import React, { useState, useEffect } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend"; // Touch backend for mobile
import { isMobile } from "react-device-detect"; // Detect mobile devices
import { useDispatch } from "react-redux";
import {
  getReview,
  createReview,
  editReview,
  deleteReview,
  sortReviews,
} from "../services/apiServices"; // Import the API actions
import { FaTrash, FaEdit, FaArrowsAlt } from "react-icons/fa";
import CommonButton from "../components/Buttons/CommonButton";
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Adjust the import based on your folder structure
import { toast } from "react-toastify";

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2">
        {children}
      </div>
    </div>
  );
};

// Review item type for drag and drop
const ItemType = "REVIEW";

// Component for each review item that can be dragged and dropped
const ReviewItem = ({
  review,
  index,
  moveReview,
  handleEdit,
  handleDelete,
}) => {
  const [, ref] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveReview(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [, drag] = useDrag({
    type: ItemType,
    item: { index },
  });

  return (
    <div
      ref={(node) => drag(ref(node))}
      className="p-4 border rounded-lg shadow-sm bg-white flex justify-between items-center cursor-move"
    >
      <div className="flex-1">
        <p className="font-bold">
          {review.personName} - {review.influence}
        </p>
        <p>{review.review}</p>
      </div>
      <div className="flex space-x-4 items-center">
        <FaArrowsAlt className="text-gray-400 cursor-move" /> {/* Drag Icon */}
        <FaEdit
          className="text-blue-500 cursor-pointer"
          onClick={() => handleEdit(review)}
        />
        <FaTrash
          className="text-red-500 cursor-pointer"
          onClick={() => handleDelete(review._id)}
        />
      </div>
    </div>
  );
};

const AdminReviewPanel = () => {
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newReview, setNewReview] = useState({
    personName: "",
    influence: "",
    review: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editReviewId, setEditReviewId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [reviewToDelete, setReviewToDelete] = useState(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchReviews();
  }, [dispatch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const fetchReviews = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getReview());
      if (response.status === "success") {
        setReviews(response.data || []);
      }
    } catch (error) {
      console.error("Failed to fetch reviews", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle opening the modal for adding/editing a review
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewReview({ personName: "", influence: "", review: "" }); // Reset review data when closing
    setIsEditing(false);
  };

  // Handle adding a new review or updating an existing review
  const handleAddReview = async () => {
    if (!newReview.personName || !newReview.influence || !newReview.review) {
      toast.error("Please fill in all fields");
      return;
    }

    setLoading(true);

    try {
      if (isEditing) {
        const payload = {
          id: editReviewId,
          ...newReview,
        };
        const response = await dispatch(editReview(payload));
        if (response.status === "success") {
          await fetchReviews(); // Re-fetch the updated reviews
          toast.success("Review edited successfully!");
        } else {
          toast.error("Failed to edit review.");
        }
      } else {
        const response = await dispatch(createReview(newReview));

        if (response.status === "success") {
          await fetchReviews(); // Re-fetch the updated reviews
          toast.success("Review added successfully!");
        } else {
          toast.error("Failed to add review.");
        }
      }
    } catch (error) {
      toast.error("Failed to create/edit review: " + error.message);
    } finally {
      setLoading(false);
      closeModal(); // Close the modal after adding/editing
    }
  };

  // Handle editing a review
  const handleEdit = (review) => {
    setIsEditing(true);
    setEditReviewId(review._id);
    setNewReview({
      personName: review.personName,
      influence: review.influence,
      review: review.review,
    });
    openModal(); // Open the modal for editing
  };

  // Handle deleting a review
  const handleDelete = (id) => {
    setReviewToDelete(id);
    setIsDeleteModalOpen(true); // Open delete confirmation modal
  };

  // Confirm review deletion
  const confirmDelete = async () => {
    setLoading(true);

    try {
      const response = await dispatch(deleteReview({ id: reviewToDelete }));
      if (response.status === "success") {
        await fetchReviews(); // Re-fetch the updated reviews after deletion
        toast.success("Review deleted successfully!");
      } else {
        toast.error("Failed to delete review.");
      }
    } catch (error) {
      toast.error("Failed to delete review: " + error.message);
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false); // Close delete confirmation modal
    }
  };

  // Move review from one index to another and trigger the sorting API
  const moveReview = (fromIndex, toIndex) => {
    const updatedReviews = [...reviews];
    const [movedReview] = updatedReviews.splice(fromIndex, 1);
    updatedReviews.splice(toIndex, 0, movedReview);
    setReviews(updatedReviews);

    // Trigger the sort API
    const payload = {
      id: movedReview._id,
      sort: toIndex + 1, // Adjust sort based on your sorting logic
    };
    dispatch(sortReviews(payload))
      .then(() => {
        toast.success("Review sorted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to sort review: " + error.message);
      });
  };

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Client Reviews</h2>

        {loading && reviews.length === 0 ? (
          <div className="flex justify-center">
            <ButtonLoader /> {/* Display the loader while loading */}
          </div>
        ) : (
          <>
            {/* Button to open modal for adding new review */}
            <CommonButton label="Add Review" onClick={openModal} />

            {/* Display Reviews (Draggable) */}
            <div className="space-y-4 mt-6">
              {reviews.map((review, index) => (
                <ReviewItem
                  key={review._id}
                  review={review}
                  index={index}
                  moveReview={moveReview}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          </>
        )}

        {/* Modal for Adding/Editing Review */}
        <Modal isOpen={isModalOpen}>
          <h3 className="text-xl font-bold mb-4">
            {isEditing ? "Edit Review" : "Add Review"}
          </h3>
          <div className="space-y-4">
            <input
              type="text"
              className="w-full p-3 border rounded-md"
              placeholder="Client Name"
              value={newReview.personName}
              onChange={(e) =>
                setNewReview({ ...newReview, personName: e.target.value })
              }
            />
            <input
              type="text"
              className="w-full p-3 border rounded-md"
              placeholder="Company"
              value={newReview.influence}
              onChange={(e) =>
                setNewReview({ ...newReview, influence: e.target.value })
              }
            />
            <textarea
              className="w-full p-3 border rounded-md"
              placeholder="Client Review"
              value={newReview.review}
              onChange={(e) =>
                setNewReview({ ...newReview, review: e.target.value })
              }
            ></textarea>
            <div className="flex justify-between items-center">
              <CommonButton
                label={isEditing ? "Edit Review" : "Add Review"}
                onClick={handleAddReview}
                disabled={loading}
              />
              <div className="mt-4 text-right">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
          <p>Are you sure you want to delete this review?</p>
          <div className="mt-4 flex justify-between items-center">
            <CommonButton
              label={loading ? <ButtonLoader /> : "Confirm"}
              onClick={confirmDelete}
              disabled={loading}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            />
            <button
              className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    </DndProvider>
  );
};

export default AdminReviewPanel;
