import React from "react";
import loginBackground from "../../images/auth/loginBackground.jpg";

function BackgroundAuth() {
  return (
    <div className="relative w-full h-full sm:flex flex-col justify-center items-center">
      <div
        className="absolute inset-0 bg-cover bg-center z-[-1] w-full h-full"
        style={{ backgroundImage: `url(${loginBackground})` }}
      ></div>
      <div className="bg-[#6244fe] bg-opacity-75 w-full h-full flex flex-col justify-center items-start text-white p-4">
        <h1 className="mb-4 text-[60px] font-semibold leading-10">Welcome</h1>
        <p className="text-sm">
          The admin panel for this application is a comprehensive and
          user-friendly interface that allows administrators to manage core
          functionalities such as user authentication, profile management, and
          consultation tracking. It includes a secure login system with options
          for password recovery through OTP verification and password reset. The
          panel also supports managing consultations, including filtering by
          status (resolved/unresolved), updating consultation statuses, and
          viewing detailed project information. The admin panel is built using
          React, with features like OTP inputs, password visibility toggles,
          pagination for managing large datasets, and loaders to enhance the
          user experience during asynchronous operations. The panel integrates
          with robust API endpoints to handle authentication, password recovery,
          and data fetching seamlessly.
        </p>
      </div>
    </div>
  );
}

export default BackgroundAuth;
