import React, { useEffect, useState } from "react";
import {
  FaProjectDiagram,
  FaCommentAlt,
  FaCheck,
  FaTimes,
} from "react-icons/fa";
import { MdPeopleAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import ButtonLoader from "../components/Loaders/ButtonLoader";
import { getStatistics } from "../services/apiServices"; // Assuming your action is in api/statisticsApi
import { useDispatch } from "react-redux";

function Dashboard() {
  const [statistics, setStatistics] = useState(null); // State to store statistics
  const [loading, setLoading] = useState(true); // State to handle loading
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        setLoading(true); // Set loading state to true
        const data = await dispatch(getStatistics()); // Fetch statistics using the API
        setStatistics(data.data); // Set the statistics data
      } catch (error) {
        console.error("Error fetching statistics:", error);
      } finally {
        setLoading(false); // Set loading state to false after the fetch is done
      }
    };

    fetchStatistics(); // Call the fetch function on component mount
  }, []);

  // Show loader while fetching data
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ButtonLoader />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 p-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">
        Admin Dashboard
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6">
        {/* Projects */}
        <Link
          to="/index/projectHistory"
          className="bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition duration-300"
        >
          <div className="flex justify-between items-center">
            <div className="text-3xl text-blue-500">
              <FaProjectDiagram />
            </div>
            <div className="text-right">
              <p className="text-xl font-semibold text-gray-700">Projects</p>
              <p className="text-4xl font-bold text-gray-900">
                {statistics.projectsCount}
              </p>
            </div>
          </div>
        </Link>

        {/* Client Reviews */}
        <Link
          to="/index/clientReview"
          className="bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition duration-300"
        >
          <div className="flex justify-between items-center">
            <div className="text-3xl text-green-500">
              <MdPeopleAlt />
            </div>
            <div className="text-right">
              <p className="text-xl font-semibold text-gray-700">
                Client Reviews
              </p>
              <p className="text-4xl font-bold text-gray-900">
                {statistics.reviewCount}
              </p>
            </div>
          </div>
        </Link>

        {/* Client Consultations */}
        <Link
          to="/index/clientConsultations"
          className="bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition duration-300"
        >
          <div className="flex justify-between items-center">
            <div className="text-3xl text-yellow-500">
              <FaCommentAlt />
            </div>
            <div className="text-right">
              <p className="text-xl font-semibold text-gray-700">
                Client Consultations
              </p>
              <p className="text-4xl font-bold text-gray-900">
                {statistics.consultationsCount}
              </p>
            </div>
          </div>
        </Link>

        {/* Resolved Consultations */}
        <Link
          to="/index/clientConsultations"
          className="bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition duration-300"
        >
          <div className="flex justify-between items-center">
            <div className="text-3xl text-green-500">
              <FaCheck />
            </div>
            <div className="text-right">
              <p className="text-xl font-semibold text-gray-700">
                Resolved Consultations
              </p>
              <p className="text-4xl font-bold text-gray-900">
                {statistics.resolvedConsultations}
              </p>
            </div>
          </div>
        </Link>

        {/* Unresolved Consultations */}
        <Link
          to="/index/clientConsultations"
          className="bg-white shadow-lg rounded-lg p-6 transform hover:scale-105 transition duration-300"
        >
          <div className="flex justify-between items-center">
            <div className="text-3xl text-red-500">
              <FaTimes />
            </div>
            <div className="text-right">
              <p className="text-xl font-semibold text-gray-700">
                Unresolved Consultations
              </p>
              <p className="text-4xl font-bold text-gray-900">
                {statistics.unResolvedConsultations}
              </p>
            </div>
          </div>
        </Link>
      </div>

      {/* Animated Summary */}
      <div className="bg-blue-200 text-center mt-12 py-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800">Overall Statistics</h2>
        <p className="text-lg text-gray-700 mt-4">
          You have managed {statistics.projectsCount} projects,{" "}
          {statistics.reviewCount} client reviews, and handled{" "}
          {statistics.consultationsCount} consultations. Out of which,{" "}
          {statistics.resolvedConsultations} are resolved and{" "}
          {statistics.unResolvedConsultations} are unresolved.
        </p>
      </div>
    </div>
  );
}

export default Dashboard;
