import React, { useState, useEffect } from "react";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend"; // Touch backend for mobile
import { isMobile } from "react-device-detect"; // Detect mobile devices
import { FaTrash, FaEdit, FaArrowsAlt } from "react-icons/fa";
import CommonButton from "../components/Buttons/CommonButton";
import FileDropper from "../components/fileDropper/FileDroper"; // Adjust the import based on your folder structure
import { useDispatch } from "react-redux";
import {
  getProjects,
  createProjects,
  editProject,
  deleteProjects,
  sortProject, // Import the sorting API
} from "../services/apiServices"; // Import the API actions
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Adjust the import based on your folder structure
import { toast } from "react-toastify";
import ImageModal from "../components/Image/ImageModel";

// Modal Component for Adding/Editing Projects
const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2">
        {children}
      </div>
    </div>
  );
};

// Deletion Confirmation Modal
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, loading }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
        <h3 className="text-xl font-bold mb-4">Confirm Deletion</h3>
        <p>Are you sure you want to delete this project?</p>
        <div className="mt-4 flex justify-between items-center">
          <CommonButton
            label={loading ? <ButtonLoader /> : "Confirm"}
            onClick={onConfirm}
            disabled={loading}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          />
          <button
            className="bg-gray-300 text-black px-4 py-2 rounded-md hover:bg-gray-400"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

// Component for each project item that can be dragged and dropped
const ProjectItem = ({
  project,
  index,
  moveProject,
  handleEdit,
  handleDelete,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [, ref] = useDrop({
    accept: "PROJECT",
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveProject(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const [, drag] = useDrag({
    type: "PROJECT",
    item: { index },
  });

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      ref={(node) => drag(ref(node))}
      className="p-4 border rounded-lg shadow-sm bg-white flex justify-between items-center cursor-move"
    >
      <div className="flex-1">
        <div
          className="relative group cursor-pointer"
          onClick={handleImageClick}
        >
          <img
            src={project.projectImage}
            alt={project.projectName}
            className="w-16 h-16 object-cover rounded-md mb-2"
          />
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity">
            <span className="text-sm">Click to Expand</span>
          </div>
        </div>
        <p className="font-bold">{project.projectName}</p>
        <p>{project.projectDescription}</p>
      </div>

      <div className="flex space-x-4 items-center">
        <FaArrowsAlt className="text-gray-400 cursor-move" /> {/* Drag Icon */}
        <FaEdit
          className="text-blue-500 cursor-pointer"
          onClick={() => handleEdit(project)}
        />
        <FaTrash
          className="text-red-500 cursor-pointer"
          onClick={() => handleDelete(project)}
        />
      </div>

      {isModalOpen && (
        <ImageModal imageUrl={project.projectImage} onClose={closeModal} />
      )}
    </div>
  );
};

const AdminProjectPanel = () => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false); // For delete button loader
  const [newProject, setNewProject] = useState({
    image: "",
    title: "",
    description: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [editProjectId, setEditProjectId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchProjects();
  }, [dispatch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getProjects());
      if (response.status === "success") {
        setProjects(response.data || []);
      }
    } catch (error) {
      console.error("Failed to fetch projects", error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file drop for FileDropper
  const handleFileDrop = (filePath) => {
    setNewProject((prev) => ({ ...prev, image: filePath }));
  };

  // Handle opening the modal for adding/editing a project
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setNewProject({ image: "", title: "", description: "" }); // Reset project data when closing
    setIsEditing(false);
  };

  // Handle adding a new project or updating an existing project
  const handleAddProject = async () => {
    if (!newProject.image || !newProject.title || !newProject.description) {
      toast.error("Please fill in all fields");
      return;
    }

    setLoading(true);

    try {
      if (isEditing) {
        const payload = {
          id: editProjectId,
          projectImage: newProject.image,
          projectName: newProject.title,
          projectDescription: newProject.description,
        };
        const response = await dispatch(editProject(payload));
        if (response.status === "success") {
          await fetchProjects(); // Re-fetch the updated projects
          toast.success("Project edited successfully!");
        } else {
          toast.error("Failed to edit project.");
        }
      } else {
        const payload = {
          projectImage: newProject.image,
          projectName: newProject.title,
          projectDescription: newProject.description,
        };

        const response = await dispatch(createProjects(payload));

        if (response.status === "success") {
          await fetchProjects(); // Re-fetch the updated projects
          toast.success("Project added successfully!");
        } else {
          toast.error("Failed to add project.");
        }
      }
    } catch (error) {
      toast.error("Failed to create/edit project: " + error.message);
    } finally {
      setLoading(false);
      closeModal(); // Close the modal after adding/editing
    }
  };

  // Handle editing a project
  const handleEdit = (project) => {
    setIsEditing(true);
    setEditProjectId(project._id);
    setNewProject({
      image: project.projectImage,
      title: project.projectName,
      description: project.projectDescription,
    });
    openModal(); // Open the modal for editing
  };

  // Handle deleting a project
  const handleDelete = (project) => {
    setProjectToDelete(project);
    setIsDeleteModalOpen(true); // Open delete confirmation modal
  };

  // Confirm project deletion
  const confirmDelete = async () => {
    setDeleteLoading(true);

    try {
      const response = await dispatch(
        deleteProjects({ id: projectToDelete._id })
      );
      if (response.status === "success") {
        await fetchProjects(); // Re-fetch the updated projects after deletion
        toast.success("Project deleted successfully!");
      } else {
        toast.error("Failed to delete project.");
      }
    } catch (error) {
      toast.error("Failed to delete project: " + error.message);
    } finally {
      setDeleteLoading(false);
      setIsDeleteModalOpen(false); // Close delete confirmation modal
    }
  };

  // Move project from one index to another and trigger the sorting API
  const moveProject = (fromIndex, toIndex) => {
    const updatedProjects = [...projects];
    const [movedProject] = updatedProjects.splice(fromIndex, 1);
    updatedProjects.splice(toIndex, 0, movedProject);
    setProjects(updatedProjects);

    // Trigger the sort API
    const payload = {
      id: movedProject._id,
      sort: toIndex + 1, // Adjust sort based on your sorting logic
    };
    dispatch(sortProject(payload))
      .then(() => {
        toast.success("Project sorted successfully!");
      })
      .catch((error) => {
        toast.error("Failed to sort project: " + error.message);
      });
  };

  return (
    <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
      <div className="p-6 bg-white rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-4">Projects</h2>

        {loading && projects.length === 0 ? (
          <div className="flex justify-center">
            <ButtonLoader /> {/* Display the loader while loading */}
          </div>
        ) : (
          <>
            {/* Button to open modal for adding new project */}
            <CommonButton label="Add Project" onClick={openModal} />

            {/* Display Projects (Draggable) */}
            <div className="space-y-4 mt-6">
              {projects.map((project, index) => (
                <ProjectItem
                  key={project._id}
                  project={project}
                  index={index}
                  moveProject={moveProject}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          </>
        )}

        {/* Modal for Adding/Editing Project */}
        <Modal isOpen={isModalOpen}>
          <h3 className="text-xl font-bold mb-4">
            {isEditing ? "Edit Project" : "Add Project"}
          </h3>
          <div className="space-y-4">
            {/* Use FileDropper for image upload */}
            <FileDropper
              title="Project Image"
              instructions="Drop an image here or click to upload"
              onFileDrop={handleFileDrop}
              fileTypes={["jpeg", "png", "jpg"]}
              defaultFile={newProject.image} // Pass the default image for preview
              maxFiles={1}
            />
            <input
              type="text"
              className="w-full p-3 border rounded-md"
              placeholder="Project Title"
              value={newProject.title}
              onChange={(e) =>
                setNewProject({ ...newProject, title: e.target.value })
              }
            />
            <textarea
              className="w-full p-3 border rounded-md"
              placeholder="Project Description"
              value={newProject.description}
              onChange={(e) =>
                setNewProject({ ...newProject, description: e.target.value })
              }
            ></textarea>
            <div className="flex justify-between items-center">
              <CommonButton
                label={
                  loading ? (
                    <ButtonLoader />
                  ) : isEditing ? (
                    "Edit Project"
                  ) : (
                    "Add Project"
                  )
                }
                onClick={handleAddProject}
                disabled={loading}
              />
              <div className="mt-4 text-right">
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-700"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal>

        {/* Delete Confirmation Modal */}
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={confirmDelete}
          loading={deleteLoading}
        />
      </div>
    </DndProvider>
  );
};

export default AdminProjectPanel;
