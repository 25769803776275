import axios from "axios";

// Set up your base URL for the API
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Replace with your actual base URL

// Function to create an Axios instance with a bearer token
const createApiClient = (store) => {
  const apiClient = axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  // Request interceptor to add token to headers
  apiClient.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("accessToken");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor to handle 401 errors
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // If the error response is 401 and this is not a retry attempt
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true; // Flag to prevent infinite loops
        try {
          const refreshResponse = await refreshToken(); // Call the refresh token API

          if (refreshResponse && refreshResponse.accessToken) {
            // Store the new tokens
            localStorage.setItem("accessToken", refreshResponse.accessToken);

            // Update the Authorization header with the new token
            apiClient.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${refreshResponse.accessToken}`;

            // Retry the original request with the new token
            return apiClient(originalRequest);
          }
        } catch (refreshError) {
          // If refresh fails, clear tokens and redirect to login
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          store.dispatch({ type: "LOGOUT" }); // Optional: Dispatch a logout action if you're using Redux
          window.location.href = "/"; // Redirect to login
          return Promise.reject(refreshError);
        }
      }

      return Promise.reject(error);
    }
  );

  return apiClient;
};

// login admin action
export const loginAdmin = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/admin/login/", payload);
    const { accessToken, refreshToken } = response.data.data;

    // Set the token in local storage
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    // Ensure the token is set before dispatching and returning the response
    if (
      localStorage.getItem("accessToken") === accessToken &&
      localStorage.getItem("refreshToken") === refreshToken
    ) {
      dispatch({ type: "STORE_ADMIN_PROFILE", payload: response.data.data });
      return response.data;
    } else {
      throw new Error("Failed to store access token.");
    }
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// Refresh token action
export const refreshToken = async () => {
  try {
    const refreshToken = localStorage.getItem("refreshToken");
    const response = await axios.post(
      `${API_BASE_URL}/admin/refreshToken/`,
      null,
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      }
    );

    const { accessToken, refreshToken: newRefreshToken } = response.data.data;
    localStorage.setItem("accessToken", accessToken);

    return { accessToken, refreshToken: newRefreshToken };
  } catch (error) {
    // Clear tokens
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");

    // Check if the tokens are successfully removed before navigating
    setTimeout(() => {
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");

      // If both tokens are removed, redirect to login page
      if (!accessToken && !refreshToken) {
        window.location.href = "/";
      } else {
        console.error("Failed to remove tokens from localStorage");
      }
    }, 100); // Check after 100 milliseconds
    throw error.response ? error.response.data : error.message;
  }
};

// get admin profile action
export const getAdminProfile = () => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.get("/admin/");
    dispatch({ type: "STORE_ADMIN_PROFILE", payload: response.data.data });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// get statistics
export const getStatistics = () => async (dispatch) => {
  try {
    // {
    //   "status": "success",
    //   "data": {
    //     "projectsCount": 0,
    //     "reviewCount": 0,
    //     "consultationsCount": 0,
    //     "resolvedConsultations": 0,
    //     "unResolvedConsultations": 0
    //   },
    //   "message": "Statistics fetched,"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/statistics/");
    // console.log("statistics=>>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// admin introduction video upload
export const introductionVideo = (payload) => async (dispatch) => {
  try {
    // sample video
    // {
    //   "introductionVideo": "introduction-video.mp4"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post("/introductionVideo/", payload);
    // console.log("introduction Video=>>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// admin introduction video
export const getIntroductionVideo = (payload) => async (dispatch) => {
  try {
    // sample response
    // {
    //   "status": "success",
    //   "data": {
    //     "_id": "66f9084a08335b4fa5f8b341",
    //     "introductionVideo": "https://mantiqbucket.s3.amazonaws.com/file_1727596138627_SampleVideo_1280x720_5mb.mp4",
    //     "createdAt": "2024-09-29T07:56:58.213Z",
    //     "updatedAt": "2024-09-29T07:56:58.213Z",
    //     "__v": 0
    //   },
    //   "message": "Introduction Video fetch Successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/introductionVideo/");
    // console.log("introduction Video=>>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

// upload file
export const uploadFiles = async (files) => {
  // sample response
  // {
  //   "status": "success",
  //   "photos": [
  //     "file_1728028743041_SampleVideo_1280x720_5mb.mp4"
  //   ]
  // }
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i], files[i].name); // Use "file" as the key
  }

  // Log the FormData contents
  for (let pair of formData.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  try {
    const apiClient = createApiClient();
    const response = await apiClient.post("/s3/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    // console.log("file response>>>>", response.data);
    return response.data;
  } catch (error) {
    // console.error("Upload error:", error);
    throw error.response.data;
  }
};

// send forget password otp
export const sendForgetPassword = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "email": "admin@example.com"
}
    */
    const response = await apiClient.post(`/admin/sendForgetOTP`, payload);
    console.log(" sendForgetPassword response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  sendForgetPassword ==> ", error);
    throw error;
  }
};
// verify otp
export const verifyOtp = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "otp": 123456,
  "email": "example@example.com"
}
    */
    const response = await apiClient.post(`/admin/verifyOTP`, payload);
    console.log(" verifyOtp response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  verifyOtp ==> ", error);
    throw error;
  }
};
// reset password
export const resetPassword = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    // sample response
    /*
{
  "email": "admin@example.com",
  "otp": "123456",
  "password": "NewStrongPassword123!"
}
    */
    const response = await apiClient.post(`/admin/resetPassword`, payload);
    console.log(" resetPassword response>>>>", response.data);

    return response.data.data;
  } catch (error) {
    console.log("failed  resetPassword ==> ", error);
    throw error;
  }
};


/* project section */

// get Projects
export const getProjects = () => async (dispatch) => {
  try {
    // {
    //   "status": "success",
    //   "data": {
    //     "projectsCount": 0,
    //     "reviewCount": 0,
    //     "consultationsCount": 0,
    //     "resolvedConsultations": 0,
    //     "unResolvedConsultations": 0
    //   },
    //   "message": "Statistics fetched,"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/projects/");
    // console.log("projects =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// create Project
export const createProjects = (payload) => async (dispatch) => {
  try {
    // example  payload
    // {
    //   "projectImage": "project-image.png",
    //   "projectName": "Project 1",
    //   "projectDescription": "This is a project description."
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post("/projects/", payload);
    // console.log("project created =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// create Project
export const editProject = (payload) => async (dispatch) => {
  try {
    // example  payload
    // {
    //   "id":"456789876",
    //   "projectImage": "project-image.png",
    //   "projectName": "Project 1",
    //   "projectDescription": "This is a project description."
    // }
    const id = payload.id;
    const editablePayload = {
      projectImage: payload.projectImage,
      projectName: payload.projectName,
      projectDescription: payload.projectDescription,
    };
    const apiClient = createApiClient();
    const response = await apiClient.put(`/projects?id=${id}`, editablePayload);
    // console.log("project edited =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// delete Project
export const deleteProjects = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/projects?id=${payload.id}`);
    // console.log("project delete =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// sort Project
export const sortProject = (payload) => async (dispatch) => {
  try {
    // example  payload
    // {
    //   "id":"456789876",
    //   "sort": 1
    // }
    const id = payload.id;
    const editablePayload = {
      sort: payload.sort,
    };
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/projects/sort?id=${id}`,
      editablePayload
    );
    // console.log("project sorted =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

/* review section */

// get reviews
export const getReview = () => async (dispatch) => {
  try {
    // example response
    // {
    //   "status": "success",
    //   "data": [
    //     {
    //       "_id": "670286f2e2d0e063d45bae8f",
    //       "personName": "John Doe",
    //       "influence": "CEO",
    //       "review": "This company is amazing!",
    //       "sort": 1,
    //       "createdAt": "2024-10-06T12:47:46.641Z",
    //       "updatedAt": "2024-10-06T12:47:46.641Z",
    //       "__v": 0
    //     }
    //   ],
    //   "message": "Reviews fetched successfully"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get("/reviews/");
    // console.log("reviews =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// create review
export const createReview = (payload) => async (dispatch) => {
  try {
    // example payload
    // {
    //   "personName": "John Doe",
    //   "influence": "CEO",
    //   "review": "This company is amazing!"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post("/reviews/", payload);
    // console.log("review created =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// edit review
export const editReview = (payload) => async (dispatch) => {
  try {
    // example  payload
    // {
    //   "id":"3456789",
    //   "personName": "John Doe",
    //   "influence": "CEO",
    //   "review": "This company is amazing!"
    // }
    const id = payload.id;
    const editablePayload = {
      personName: payload.personName,
      influence: payload.influence,
      review: payload.review,
    };
    const apiClient = createApiClient();
    const response = await apiClient.put(`/reviews?id=${id}`, editablePayload);
    // console.log("review edited =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// delete review
export const deleteReview = (payload) => async (dispatch) => {
  try {
    const apiClient = createApiClient();
    const response = await apiClient.delete(`/reviews?id=${payload.id}`);
    // console.log("review delete =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// sort review
export const sortReviews = (payload) => async (dispatch) => {
  try {
    // example  payload
    // {
    //   "id":"456789876",
    //   "sort": 1
    // }
    const id = payload.id;
    const editablePayload = {
      sort: payload.sort,
    };
    const apiClient = createApiClient();
    const response = await apiClient.put(
      `/reviews/sort?id=${id}`,
      editablePayload
    );
    // console.log("reviews sorted =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

/* consultation section */

// get consultations
export const getConsultation = (payload) => async (dispatch) => {
  try {
    // example response
    // {
    //   "status": "success",
    //   "data": [
    //     {
    //       "_id": "6702c0926cd02e42bae660ac",
    //       "firstName": "consultation 2",
    //       "lastName": "string",
    //       "email": "mantiqsoft@gmail.com",
    //       "phoneNumber": "string",
    //       "companyName": "string",
    //       "region": "string",
    //       "projectDetail": "string",
    //       "isResolved": true,
    //       "createdAt": "2024-10-06T16:53:38.992Z",
    //       "updatedAt": "2024-10-06T16:54:49.166Z",
    //       "__v": 0
    //     },
    //     {
    //       "_id": "6702c0876cd02e42bae660aa",
    //       "firstName": "string",
    //       "lastName": "string",
    //       "email": "mantiqsoft@gmail.com",
    //       "phoneNumber": "string",
    //       "companyName": "string",
    //       "region": "string",
    //       "projectDetail": "string",
    //       "isResolved": false,
    //       "createdAt": "2024-10-06T16:53:27.099Z",
    //       "updatedAt": "2024-10-06T16:53:27.099Z",
    //       "__v": 0
    //     }
    //   ],
    //   "message": "Consultations fetched successfully"
    // }
    // example payload
    // {
    //   "filter":0  // 0 all , 1 resolved , 2 unresolved
    // }
    const apiClient = createApiClient();
    const response = await apiClient.get(
      `/consultation?filter=${payload.number}`
    );
    // console.log("consultations =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
// edit review
export const editConsultationMarkResolveUnResolve =
  (payload) => async (dispatch) => {
    try {
      // example  payload
      // {
      //   "id":"3456789",
      // }
      const id = payload.id;
      const apiClient = createApiClient();
      const response = await apiClient.put(`/consultation/resolve?id=${id}`);
      // console.log("consultation status updated =>", response.data);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };
// logout api
export const logout = (payload) => async () => {
  try {
    // example payload
    // {
    //   refreshToken:"jhkhgfg"
    // }
    const apiClient = createApiClient();
    const response = await apiClient.post("/admin/logout", payload);

    // console.log("logout =>", response.data);
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};
