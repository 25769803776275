import { createBrowserRouter } from "react-router-dom";
// pages
import AdminIndex from "../pages/AdminIndex";
import Dashboard from "../pages/Dashboard";
import ProjectHistory from "../pages/ProjectHistory";
import ClientReview from "../pages/ClientReview";
import ClientConsultation from "../pages/ClientConsultation";
import LoginAdmin from "../pages/auth/login";
import CompanyIntroductionVideo from "../pages/CompanyIntroductionVideo";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginAdmin />,
    errorElement: <>error</>,
    children: [],
  },
  {
    path: "/index",
    element: <AdminIndex />,
    errorElement: <>error</>,
    children: [
      {
        path: "/index",
        element: <Dashboard />,
        errorElement: <>error</>,
        children: [],
      },
      {
        path: "/index/CompanyIntroductionVideo",
        element: <CompanyIntroductionVideo />,
        errorElement: <>error</>,
        children: [],
      },
      {
        path: "/index/projectHistory",
        element: <ProjectHistory />,
        errorElement: <>error</>,
        children: [],
      },
      {
        path: "/index/clientReview",
        element: <ClientReview />,
        errorElement: <>error</>,
        children: [],
      },
      {
        path: "/index/clientConsultations",
        element: <ClientConsultation />,
        errorElement: <>error</>,
        children: [],
      },
    ],
  },
]);

export default router;
