import React, { useState, useEffect } from "react";
import CommonButton from "../components/Buttons/CommonButton.jsx";
// import Pagination from "../components/Pagination/Pagination";
import { GoPlus } from "react-icons/go";
import { FiMinus } from "react-icons/fi";
import { useDispatch } from "react-redux";
import {
  getConsultation,
  editConsultationMarkResolveUnResolve,
} from "../services/apiServices"; // Import the API actions
import ButtonLoader from "../components/Loaders/ButtonLoader"; // Import the ButtonLoader
import { toast } from "react-toastify";

const ClientConsultation = () => {
  const dispatch = useDispatch();
  const [consultations, setConsultations] = useState([]);
  const [filter, setFilter] = useState("all");
  // const [currentPage, setCurrentPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedConsultation, setSelectedConsultation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleLoading, setToggleLoading] = useState(null); // New state for toggle button loader
  const [modalLoading, setModalLoading] = useState(false); // New state for modal close button loader
  // const consultationsPerPage = 5;

  // Fetch consultations from the API on component mount and when filter changes
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchConsultations();
  }, [filter]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const fetchConsultations = async () => {
    try {
      setLoading(true);
      let filterNumber = 0; // 0 for all, 1 for resolved, 2 for unresolved

      if (filter === "resolved") filterNumber = 1;
      if (filter === "unresolved") filterNumber = 2;

      const response = await dispatch(
        getConsultation({ number: filterNumber })
      );
      if (response.status === "success") {
        setConsultations(response.data || []);
      }
    } catch (error) {
      toast.error("Failed to fetch consultations: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Pagination logic
  // const totalPages = Math.ceil(consultations.length / consultationsPerPage);
  // const indexOfLastConsultation = currentPage * consultationsPerPage;
  // const indexOfFirstConsultation =
  //   indexOfLastConsultation - consultationsPerPage;
  // const currentConsultations = consultations.slice(
  //   indexOfFirstConsultation,
  //   indexOfLastConsultation
  // );

  // Handle resolving/unresolving consultations
  const toggleStatus = async (id) => {
    try {
      setToggleLoading(id); // Set loading for the specific consultation
      const response = await dispatch(
        editConsultationMarkResolveUnResolve({ id })
      );
      if (response.status === "success") {
        toast.success("Consultation status updated successfully!");
        fetchConsultations(); // Re-fetch the consultations after status change
      } else {
        toast.error("Failed to update consultation status.");
      }
    } catch (error) {
      toast.error("Failed to update status: " + error.message);
    } finally {
      setToggleLoading(null); // Reset loading after the action completes
    }
  };

  // Open modal to view consultation details
  const viewDetails = (consultation) => {
    setSelectedConsultation(consultation);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setModalLoading(true);
    setTimeout(() => {
      setSelectedConsultation(null);
      setIsModalOpen(false);
      setModalLoading(false); // Reset the modal loading state
    }, 500); // Simulate closing delay for UX effect
  };

  // Handle row expand for small screens
  const handleRowExpand = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-gray-700">
        Client Consultations
      </h2>

      {/* Filter Dropdown */}
      <div className="mb-6 flex justify-between">
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="w-full p-3 border rounded-md bg-gray-50 focus:ring focus:ring-indigo-200"
        >
          <option value="all">All</option>
          <option value="resolved">Resolved</option>
          <option value="unresolved">Unresolved</option>
        </select>
      </div>

      {/* Consultation Table */}
      <div className="overflow-hidden relative">
        <table className="table-auto w-full text-left border-collapse">
          <thead>
            <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
              <th className="py-3 px-6">Name</th>
              <th className="hidden md:table-cell py-3 px-6">Company</th>
              <th className="py-3 px-6">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3" className="text-center py-3">
                  Loading consultations...
                </td>
              </tr>
            ) : (
              consultations.map((consultation, index) => (
                <React.Fragment key={consultation._id}>
                  <tr className="border-b border-gray-200 hover:bg-gray-100">
                    <td className="py-3 px-6 whitespace-nowrap flex items-center">
                      {expandedRow === index ? (
                        <button
                          className="bg-red-500 text-white rounded-full p-1"
                          onClick={() => handleRowExpand(index)}
                        >
                          <FiMinus />
                        </button>
                      ) : (
                        <button
                          className="bg-green-500 text-white rounded-full p-1"
                          onClick={() => handleRowExpand(index)}
                        >
                          <GoPlus />
                        </button>
                      )}
                      <span className="ml-2">
                        {consultation.firstName} {consultation.lastName}
                      </span>
                    </td>
                    <td className="hidden md:table-cell py-3 px-6">
                      {consultation.companyName}
                    </td>
                    <td className="py-3 px-6">
                      <div className="flex flex-wrap items-center gap-4">
                        <CommonButton
                          label={
                            toggleLoading === consultation._id ? (
                              <ButtonLoader /> // Show loader when loading
                            ) : consultation.isResolved ? (
                              "Mark Unresolved"
                            ) : (
                              "Mark Resolved"
                            )
                          }
                          onClick={() => toggleStatus(consultation._id)}
                          className="text-sm"
                          disabled={toggleLoading === consultation._id} // Disable button when loading
                        />
                        <CommonButton
                          label="View Details"
                          className="text-sm"
                          onClick={() => viewDetails(consultation)}
                        />
                      </div>
                    </td>
                  </tr>

                  {/* Expanded Row for Small Screens */}
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="3" className="py-3 px-6 bg-gray-50">
                        <div className="p-4 border border-gray-300 rounded-md">
                          <p>
                            <strong>FirstName:</strong> {consultation.firstName}
                          </p>
                          <p>
                            <strong>LastName:</strong> {consultation.lastName}
                          </p>
                          <p>
                            <strong>Email:</strong> {consultation.email}
                          </p>
                          <p>
                            <strong>Phone Number:</strong>{" "}
                            {consultation.phoneNumber}
                          </p>
                          <p>
                            <strong>Company Name:</strong>{" "}
                            {consultation.companyName}
                          </p>
                          <p>
                            <strong>Region:</strong> {consultation.region}
                          </p>
                          <p>
                            <strong>Project Details:</strong>{" "}
                            {consultation.projectDetail}
                          </p>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {/* <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={setCurrentPage}
      /> */}

      {/* Project Details Modal */}
      {isModalOpen && selectedConsultation && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 md:w-1/2">
            <h3 className="text-2xl font-bold mb-4">Project Details</h3>
            <p>
              <strong>FirstName:</strong> {selectedConsultation.firstName}
            </p>
            <p>
              <strong>LastName:</strong> {selectedConsultation.lastName}
            </p>
            <p>
              <strong>Email:</strong> {selectedConsultation.email}
            </p>
            <p>
              <strong>Phone Number:</strong> {selectedConsultation.phoneNumber}
            </p>
            <p>
              <strong>Company Name:</strong> {selectedConsultation.companyName}
            </p>
            <p>
              <strong>Region:</strong> {selectedConsultation.region}
            </p>
            <p>
              <strong>Project Details:</strong>{" "}
              {selectedConsultation.projectDetail}
            </p>

            <div className="mt-6 text-right">
              <CommonButton
                label={modalLoading ? <ButtonLoader /> : "Close"} // Show loader on modal close button
                onClick={closeModal}
                disabled={modalLoading} // Disable button when loading
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientConsultation;
