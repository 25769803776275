import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaFile } from "react-icons/fa";
import ButtonLoader from "../Loaders/ButtonLoader"; // Adjust the import according to your project structure
import { uploadFiles } from "../../services/apiServices"; // Adjust the import according to your project structure
import { toast } from "react-toastify";
import ImageModal from "../Image/ImageModel";

const FileDropper = ({
  title,
  instructions,
  onFileDrop,
  type,
  fileTypes,
  defaultFile,
  maxFiles = 1, // Default max files to 1 if not provided
}) => {
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const videoRefs = useRef([]); // Use an array of refs for multiple videos

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleVideoControl = useCallback(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        const { videoWidth, videoHeight } = video;

        video.onplay = async () => {
          if (video.requestFullscreen) {
            await video.requestFullscreen();
          } else if (video.mozRequestFullScreen) {
            await video.mozRequestFullScreen(); // Firefox
          } else if (video.webkitRequestFullscreen) {
            await video.webkitRequestFullscreen(); // Chrome, Safari, and Opera
          } else if (video.msRequestFullscreen) {
            await video.msRequestFullscreen(); // IE/Edge
          }

          const isFullScreen = document.fullscreenElement === video;

          if (isFullScreen) {
            video.play();
          } else if (videoWidth <= 1280 || videoHeight <= 720) {
            video.pause();
            video.onplay = () => video.pause();
          } else {
            video.play();
          }
        };
      }
    });
  }, []);

  const handleFullScreenChange = useCallback(() => {
    videoRefs.current.forEach((video) => {
      if (video) {
        const isFullScreen = document.fullscreenElement === video;
        const { videoWidth, videoHeight } = video;

        if (!isFullScreen && (videoWidth <= 1280 || videoHeight <= 720)) {
          video.pause();
          video.onplay = () => video.pause();
        }
      }
    });
  }, []);

  useEffect(() => {
    const checkVideoSize = () => {
      handleVideoControl();
    };

    checkVideoSize();

    videoRefs.current.forEach((video) => {
      if (video) {
        video.onloadedmetadata = checkVideoSize;
      }
    });

    window.addEventListener("resize", checkVideoSize);
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      window.removeEventListener("resize", checkVideoSize);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleVideoControl, handleFullScreenChange, previewFile, droppedFiles]);

  useEffect(() => {
    if (droppedFiles.length) {
      // handle dropped files logic
    } else if (defaultFile) {
      console.log("hi>>", defaultFile);
      const fileType = defaultFile.split(".").pop(); // Get the file extension
      setPreviewFile({ fileURL: defaultFile, fileType, isDefault: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFile]);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      if (acceptedFiles.length > maxFiles) {
        toast.error(`You can only upload a maximum of ${maxFiles} file(s).`);
        return;
      }

      const validFiles = acceptedFiles.filter((file) => {
        const fileType = file.type.split("/")[1]; // Get the file extension/type
        if (fileTypes.includes(fileType)) {
          return true;
        } else {
          toast.error(`File type ${fileType} is not supported.`);
          return false;
        }
      });

      if (validFiles.length === 0) return; // Stop if no valid files

      setDroppedFiles(validFiles);
      setLoading(true);

      try {
        // Upload valid files
        const response = await uploadFiles(validFiles);
        const uploadedFilePath = response.photos[0]; // Update based on your API response

        // Notify parent component about the uploaded file and its type
        onFileDrop(uploadedFilePath, type);
        toast.success("File uploaded successfully!");

        // Set the preview file for the first dropped file
        // const fileType = validFiles[0].type.split("/")[0];
        // setPreviewFile({
        //   fileURL: URL.createObjectURL(validFiles[0]),
        //   fileType,
        //   isDefault: false,
        // });

        // Apply video control based on video size
        handleVideoControl();
      } catch (error) {
        toast.error(error.message);
        console.error("File upload error:", error);
      } finally {
        setLoading(false);
      }
    },
    [fileTypes, maxFiles, onFileDrop, type, handleVideoControl]
  );

  const removeFile = (e) => {
    e.stopPropagation(); // Prevent triggering the file explorer
    setDroppedFiles([]);
    setPreviewFile(null);
    onFileDrop(null, type); // Notify the parent component about file removal
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, maxFiles });

  return (
    <div
      {...getRootProps()}
      className={`flex flex-col items-center justify-center w-full h-32 p-6 border-2 border-dashed rounded-md cursor-pointer 
        ${
          droppedFiles.length > 0 || previewFile
            ? "border-blue-400"
            : "border-blue-400"
        } bg-blue-200`}
    >
      <input {...getInputProps()} />
      <div className="flex flex-row items-center space-x-4">
        <FaFile className="w-8 h-8" />
        <div className="sm:text-lg text-[10px]">
          <p className="text-gray-600">{title}</p>
          <p className="text-xs text-slate-400">{instructions}</p>
        </div>
      </div>
      {loading && (
        <div className="mt-2">
          <ButtonLoader /> {/* Display the loader while uploading */}
        </div>
      )}
      {!droppedFiles.length && previewFile && !loading && (
        <div className="mt-2 flex space-x-2">
          {["jpeg", "png", "jpg", "PNG"].includes(previewFile.fileType) && (
            <div>
              <div
                className="relative group cursor-pointer" // Add cursor-pointer to indicate clickability
                onClick={handleImageClick}
              >
                <img
                  src={previewFile.fileURL}
                  alt="preview"
                  className="w-16 h-16 object-cover rounded-md"
                />
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white opacity-0 group-hover:opacity-100 transition-opacity">
                  <span className="text-sm">Click to Expand</span>
                </div>
              </div>

              {isModalOpen && (
                <ImageModal
                  imageUrl={previewFile.fileURL}
                  onClose={closeModal}
                />
              )}
            </div>
          )}
          {["mp4", "mov", "avi"].includes(previewFile.fileType) && (
            <video
              ref={(el) => (videoRefs.current[0] = el)}
              src={previewFile.fileURL}
              className="w-16 h-16 object-cover rounded-md"
              controls
            />
          )}
          {["pdf", "doc", "docx"].includes(previewFile.fileType) && (
            <embed
              src={previewFile.fileURL}
              type="application/pdf"
              className="w-16 h-16 object-cover rounded-md"
            />
          )}
          <button
            className="ml-2 px-2 py-1 bg-red-500 text-white rounded-md text-xs"
            onClick={removeFile}
          >
            Remove
          </button>
        </div>
      )}
      {!loading && droppedFiles.length > 0 && (
        <div className="mt-2 flex space-x-2">
          {droppedFiles.map((file, index) => {
            const fileType = file.type.split("/")[0];
            const fileURL = URL.createObjectURL(file);
            if (fileType === "image") {
              return (
                <img
                  key={file.name}
                  src={fileURL}
                  alt={file.name}
                  className="w-16 h-16 object-cover rounded-md"
                />
              );
            } else if (fileType === "video") {
              return (
                <video
                  ref={(el) => (videoRefs.current[index + 1] = el)}
                  key={file.name}
                  src={fileURL}
                  className="w-16 h-16 object-cover rounded-md"
                  controls
                />
              );
            } else if (file.type === "application/pdf") {
              return (
                <embed
                  key={file.name}
                  src={fileURL}
                  type="application/pdf"
                  className="w-16 h-16 object-cover rounded-md"
                />
              );
            } else {
              return (
                <p key={file.name} className="text-xs text-gray-600">
                  {file.name}
                </p>
              );
            }
          })}
          <button
            className="ml-2 px-2 py-1 bg-red-500 text-white rounded-md text-xs"
            onClick={removeFile}
          >
            Remove
          </button>
        </div>
      )}
    </div>
  );
};

export default FileDropper;
