import React from "react";

const CommonButton = ({
  label,
  onClick,
  className = "",
  disabled = false,
  rightIcon,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`px-4 py-2 flex justify-between items-center bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition ${className}`}
    >
      {label}
      {rightIcon && <span className="ml-2">{rightIcon}</span>}
    </button>
  );
};

export default CommonButton;
